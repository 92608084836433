<template>
    <section id="welcome-title">
      <h2>Marinka Grondel</h2>
    </section>
  <div id="intro-container" v-if="entryImage">
      <div class="img-container" @mouseenter="startReflection($event)" @mouseleave="stopReflection($event)">
        <ImageLoader class="image1" :small="$store.state.$imageBaseURL + entryImage.id + '/' + entryImage.cover1 + '?w=32&q=50&dpr=2&token=' + $store.state.$imageToken" :trueSize="$store.state.$imageHackURL + entryImage.id + '/' + entryImage.cover1"/>

        <ImageLoader class="image2" :small="$store.state.$imageBaseURL + entryImage.id + '/' + entryImage.cover2 + '?w=32&q=50&dpr=2&token=' + $store.state.$imageToken" :trueSize="$store.state.$imageHackURL + entryImage.id + '/' + entryImage.cover2"/>
      </div>
  </div>
</template>


<script>
import ImageLoader from '../components/ImageLoader.vue'

export default {
  components: {
    ImageLoader
  },
  data() {
    return {
      entryImage: null
    }
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    fetchContent() {
      fetch(`https://marinkagrondel.com/cms/api/entries?id=intro-page/image&token=${process.env.VUE_APP_ENTRY_TOKEN}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not OK');
      } else {
        response.json()
          .then((JSON) => {
              // convert Object to Array !!!
              // const entries = Object.values(JSON.data);
              this.entryImage = JSON.data;
          })
      }
    })
    },
    startReflection(e) {
      console.log("help");
      let gradientEl = document.createElement("DIV");
      gradientEl.classList.add("nour-gradient");
      e.target.appendChild(gradientEl);

      e.target.addEventListener('mousemove', (e) => {
        this.cursorPos(e, gradientEl)
      });
      e.target.style.backgroundColor = 'grey';
      gradientEl.style.opacity = 1;
    },
    stopReflection(e) {
      let gradientEl = document.getElementsByClassName("nour-gradient")[0];
      e.target.removeChild(gradientEl);
      e.target.style.backgroundColor = 'unset';
    },
    cursorPos(event, el) {
      let rect = event.target.getBoundingClientRect();
      var x = event.clientX - rect.left;
      el.style.transform = "translateX(" + (x - (rect.width / 2)) + "px)";
    }
  }

}
</script>

<style>
#intro-container {
  position: absolute;
  top: 0;
  width:100%;
  height: 100%;
  background-color: var(--nour-bg-color);
  /* cursor: pointer; */
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#welcome-title {
  position: relative;
  top: 0%;
  /* transform: translateY(-50%); */
  margin: 0 auto;
  z-index:40;
  padding-top:1rem;
  text-transform: uppercase;
  text-align: center;
}

#welcome-title h2 {
  font-size: 3rem;
  color: rgb(218, 218, 244) !important;
  line-height: 1;
  opacity: 0.6;
  transition: all 1s;
  /* user-select: none; */
}

#intro-container .img-container {
  height:100%;
  width: 100%;
  position:relative;
  overflow:hidden;
  flex-shrink: 0;
  flex-grow: 1;
  transition: background-color 0.5s;
  min-height: 200vh;
}

.nour-gradient {
  position:absolute;
  top:0;
  margin:0;
  padding:0;
  width:100%;
  height:100%;
  background-image: url('../assets/reflection.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 80% 50%;
  z-index:0;
  opacity: 0;
  will-change: transform;
}

#intro-container .img-container img {
  object-fit: cover;

  display:block;
  width:100%;
  height:100%;
}

.image1, .image2 {
  top:0px;
  left:0px;
}

.image1 {
  position:relative;
  z-index:5;
}

.image2 {
  position:absolute;
  z-index:10;
}

#intro-container .image1 {
  opacity: 1;
}

#intro-container:hover .image1 {
  opacity: 0.85 !important;
}

#intro-container .image2 {
  opacity:1;
  mix-blend-mode: lighten;
}

.fade-enter-active {
  mix-blend-mode: multiply;
  transition: opacity 0.2s ease 0.3s;
}
.fade-leave-active {
  mix-blend-mode: multiply;
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 900px){
  #welcome-title h2 {
    font-size: 1.8rem;
  }
  
}

</style>