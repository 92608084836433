<template>
  <router-view>
  </router-view>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      window: { width: null, height: null }
    }
  },
  computed: {
    currentSeries() {
      return this.$store.state.$active;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.checkForMobile();      
  },
  methods: {
    handleResize() {
            this.checkForMobile(); 
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
    },
    checkForMobile() {
      let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (window.innerWidth <= 768 || isMobile) {
        this.$store.state.$mobile = true;
      }
      else { 
        this.$store.state.$mobile = false;
      }
    }
  }
}
</script>

<style>
#app {
  position:absolute;
  width:100%;
  height:100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction:column;
  background-color: var(--shop-bg-color);
}

#sidebar {
  position:absolute;
  width:100%;
  padding:14px 14px 22px 14px ;
  background-color: transparent;
  z-index:50;
  bottom: 0;
  right:0;
  flex-shrink:0;
  flex-grow:0;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

#sidebar a {
  /* font-family: Everson; */
  font-size:max(32px, 1.35vw);
  padding: 8px 60px 0px 20px;
  user-select: none;
}

@media only screen and (max-width: 1000px) {
  #app {
    flex-direction:column;
  }

  #sidebar a {
    font-size: max(1.4vw, 30px);
    padding: 0px 40px 0px 20px;

  }
}

</style>
