<template>
  <img :src="imageSrc" :class="{ loading : isLoading}" @load="isLoading = false"/>
</template>

<script>
export default {
  name: "ImageLoader",
  props: ['small', 'trueSize'],
  data() {
      return {
        imageSrc: this.small,
        isLoading: true
      }
  },
  mounted() {
    let img = new Image();
    this.isLoading = true;
    let VueThis = this;
    img.onload = function() {
      VueThis.isLoading= true;
      VueThis.imageSrc = VueThis.trueSize;
    }
    img.src = this.imageSrc;
  }
}
</script>

<style scoped>
img {
  width:100%;
  height:100%;
}

img.loading {
    filter: blur(5px);
        -webkit-filter: blur(8px);
        -moz-filter: blur(8px);
        -o-filter: blur(8px);
        -ms-filter: blur(8px);
}
</style>