import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import './assets/css/global.css'
import router from './router'


// Create a new store instance.
const store = createStore({
  state () {
    return {
      $mobile: null,
      $active: 1,
      $imageHackURL: process.env.VUE_APP_BASE_URL + "cms/project/media/entries/",
      $imageBaseURL: process.env.VUE_APP_BASE_URL + "cms/api/images/entries/",
      $fileBaseURL: process.env.VUE_APP_BASE_URL + "cms/project/media/entries/",
      $imageToken: "036f67aaf83d0d63557c6dec9744c944"

    }
  },
  mutations: {
    swapSeries(state, value) {
      state.$active = value;
    }
  }
})

const app = createApp(App).use(router)

app.config.globalProperties.window = window;

app
.use(store)

app.mount("#app");
