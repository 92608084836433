import { createRouter, createWebHistory } from 'vue-router'
import welcome from '../views/Welcome.vue'

const routes = [
  {
    path: '/',
    name: 'Marinka Grondel',
    component: welcome
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `Marinka Grondel | ${to.name}`;
  next();
});

export default router
